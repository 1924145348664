.dropdown-full{
    max-height: 200px;
    transition: max-height 400ms ease-in-out;
    overflow-y: scroll;
	overflow-x:hidden;
}

.dropdown{
    max-height:0;
    transition: max-height 400ms ease-in-out;
    overflow-y: scroll;
	overflow-x:hidden;
}


